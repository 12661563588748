import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _6263cac0 = () => interopDefault(import('../modules/contact/pages/ContactConfirmation.vue' /* webpackChunkName: "" */))
const _235fbeaf = () => interopDefault(import('../modules/contact/pages/ContactForm.vue' /* webpackChunkName: "" */))
const _e7633602 = () => interopDefault(import('../modules/cart/pages/Cart.vue' /* webpackChunkName: "" */))
const _0bf739f7 = () => interopDefault(import('../modules/cart/pages/ApprovalConfirmation.vue' /* webpackChunkName: "" */))
const _0384ba3f = () => interopDefault(import('../modules/checkout/pages/Checkout.vue' /* webpackChunkName: "" */))
const _4f1e9672 = () => interopDefault(import('../modules/checkout/pages/Checkout/UserAccount.vue' /* webpackChunkName: "" */))
const _125b334e = () => interopDefault(import('../modules/checkout/pages/Checkout/Shipping.vue' /* webpackChunkName: "" */))
const _1f91948b = () => interopDefault(import('../modules/checkout/pages/Checkout/Billing.vue' /* webpackChunkName: "" */))
const _837e32d4 = () => interopDefault(import('../modules/checkout/pages/Checkout/Payment.vue' /* webpackChunkName: "" */))
const _082e0216 = () => interopDefault(import('../modules/checkout/pages/Checkout/ThankYou.vue' /* webpackChunkName: "" */))
const _1546630a = () => interopDefault(import('../modules/checkout/pages/Checkout/RedirectCb.vue' /* webpackChunkName: "" */))
const _2200b8ab = () => interopDefault(import('../modules/customer/pages/ConnectAs.vue' /* webpackChunkName: "" */))
const _f0224496 = () => interopDefault(import('../modules/customer/pages/MyAccount/ResetForgottenPassword.vue' /* webpackChunkName: "" */))
const _62fdb7b6 = () => interopDefault(import('../modules/customer/pages/MyAccount/ForgottenPassword.vue' /* webpackChunkName: "" */))
const _162d09d3 = () => interopDefault(import('../modules/customer/pages/MyAccount/EnteredPassword.vue' /* webpackChunkName: "" */))
const _27749ba7 = () => interopDefault(import('../modules/customer/pages/MyAccount/RegisterConfirmation.vue' /* webpackChunkName: "" */))
const _18831046 = () => interopDefault(import('../modules/customer/pages/MyAccount/CreateWebAccess.vue' /* webpackChunkName: "" */))
const _ce40c228 = () => interopDefault(import('../modules/customer/pages/MyAccount/AccesEspacePro.vue' /* webpackChunkName: "" */))
const _06a1fac2 = () => interopDefault(import('../modules/customer/pages/MyAccount/MyAccount.vue' /* webpackChunkName: "" */))
const _541246a8 = () => interopDefault(import('../modules/customer/pages/MyAccount/MyProfile/MyProfile.vue' /* webpackChunkName: "" */))
const _10c5d095 = () => interopDefault(import('../modules/customer/pages/MyAccount/MyGeneralConditions.vue' /* webpackChunkName: "" */))
const _1d857c30 = () => interopDefault(import('../modules/customer/pages/MyAccount/AddressesDetails/AddressesDetails.vue' /* webpackChunkName: "" */))
const _b6eab608 = () => interopDefault(import('../modules/customer/pages/MyAccount/AddressesDetails/AddressNew.vue' /* webpackChunkName: "" */))
const _0661e944 = () => interopDefault(import('../modules/customer/pages/MyAccount/AddressesDetails/AddressEdit.vue' /* webpackChunkName: "" */))
const _a9a72d98 = () => interopDefault(import('../modules/customer/pages/MyAccount/MyNewsletter.vue' /* webpackChunkName: "" */))
const _7d90f100 = () => interopDefault(import('../modules/customer/pages/MyAccount/MyWishlist.vue' /* webpackChunkName: "" */))
const _41172270 = () => interopDefault(import('../modules/customer/pages/MyAccount/OrderHistory/OrderHistory.vue' /* webpackChunkName: "" */))
const _1acd6a83 = () => interopDefault(import('../modules/customer/pages/MyAccount/OrderHistory/SingleOrder/SingleOrder.vue' /* webpackChunkName: "" */))
const _251bbdb0 = () => interopDefault(import('../modules/customer/pages/MyAccount/MyReviews.vue' /* webpackChunkName: "" */))
const _152a38f5 = () => interopDefault(import('../modules/customer/pages/MyAccount/Dashboard.vue' /* webpackChunkName: "" */))
const _a6bae846 = () => interopDefault(import('../modules/customer/pages/MyAccount/MyCreditNotes.vue' /* webpackChunkName: "" */))
const _7b72f539 = () => interopDefault(import('../modules/customer/pages/MyAccount/MyFrequentPurchases.vue' /* webpackChunkName: "" */))
const _5165f9e2 = () => interopDefault(import('../modules/customer/pages/MyAccount/MyQuotations.vue' /* webpackChunkName: "" */))
const _183d5480 = () => interopDefault(import('../modules/customer/pages/MyAccount/OrderHistory/SingleOrder/PictimeSingleQuotation.vue' /* webpackChunkName: "" */))
const _7f9693eb = () => interopDefault(import('../modules/customer/pages/MyAccount/ResetPassword.vue' /* webpackChunkName: "" */))
const _ddd2e072 = () => interopDefault(import('../modules/customer/pages/MyAccount/SubAccount/MySubAccounts.vue' /* webpackChunkName: "" */))
const _8da45724 = () => interopDefault(import('../modules/customer/pages/MyAccount/SubAccount/ManageSubAccounts.vue' /* webpackChunkName: "" */))
const _449da56e = () => interopDefault(import('../modules/customer/pages/MyAccount/OrdersWaitingForApproval/OrdersWaitingForApprovalList.vue' /* webpackChunkName: "" */))
const _7f388da1 = () => interopDefault(import('../modules/customer/pages/MyAccount/OrdersWaitingForApproval/OrdersWaitingForApprovalDetail.vue' /* webpackChunkName: "" */))
const _074de8ac = () => interopDefault(import('../modules/catalog/pages/category.vue' /* webpackChunkName: "" */))
const _64d22eb4 = () => interopDefault(import('../modules/catalog/pages/page-ibexia.vue' /* webpackChunkName: "" */))
const _5bc47273 = () => interopDefault(import('../modules/catalog/pages/product.vue' /* webpackChunkName: "" */))
const _07bf8c62 = () => interopDefault(import('../modules/catalog/pages/no-result-found.vue' /* webpackChunkName: "" */))
const _2cd3849d = () => interopDefault(import('../pages/Page.vue' /* webpackChunkName: "" */))
const _46c32226 = () => interopDefault(import('../pages/Home.vue' /* webpackChunkName: "" */))
const _0fb3efcf = () => interopDefault(import('../layouts/error.vue' /* webpackChunkName: "" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/contact-confirmation",
    component: _6263cac0,
    meta: {"titleLabel":"Cart"},
    name: "ContactConfirmation"
  }, {
    path: "/contactez-nous",
    component: _235fbeaf,
    name: "ContactForm"
  }, {
    path: "/cart",
    component: _e7633602,
    meta: {"titleLabel":"Cart"},
    name: "cart"
  }, {
    path: "/approval-confirmation",
    component: _0bf739f7,
    name: "approval-confirmation"
  }, {
    path: "/checkout",
    component: _0384ba3f,
    name: "checkout",
    children: [{
      path: "user-account",
      component: _4f1e9672,
      name: "user-account"
    }, {
      path: "shipping",
      component: _125b334e,
      name: "shipping"
    }, {
      path: "billing",
      component: _1f91948b,
      name: "billing"
    }, {
      path: "payment",
      component: _837e32d4,
      name: "payment"
    }]
  }, {
    path: "/thank-you",
    component: _082e0216,
    name: "thank-you"
  }, {
    path: "/thankcb-you",
    component: _1546630a,
    name: "thankcb-you"
  }, {
    path: "/connect-as/:token",
    component: _2200b8ab,
    meta: {"titleLabel":"Connexion"},
    name: "connect-as"
  }, {
    path: "/reset-forgotten-password",
    component: _f0224496,
    meta: {"titleLabel":"Forgotten password"},
    name: "customer-reset-forgotten-password"
  }, {
    path: "/mot-de-passe-oublie",
    component: _62fdb7b6,
    meta: {"titleLabel":"Forgotten password"},
    name: "customer-forgotten-password"
  }, {
    path: "/entered-password",
    component: _162d09d3,
    meta: {"titleLabel":"Entered password"},
    name: "customer-entered-password"
  }, {
    path: "/registration-confirmed",
    component: _27749ba7,
    name: "customer-registration-confirmed"
  }, {
    path: "/sign-in",
    component: _18831046,
    name: "customer-sign-in"
  }, {
    path: "/acces-espace-pro",
    component: _ce40c228,
    meta: {"titleLabel":"Create or access my account"},
    name: "customer-acces-espace-pro"
  }, {
    path: "/customer",
    component: _06a1fac2,
    meta: {"titleLabel":"My Account"},
    name: "customer",
    children: [{
      path: "my-profile",
      component: _541246a8,
      meta: {"titleLabel":"My profile"},
      name: "customer-my-profile"
    }, {
      path: "my-general-conditions",
      component: _10c5d095,
      meta: {"titleLabel":"My general conditions"},
      name: "customer-my-general-conditions"
    }, {
      path: "addresses-details",
      component: _1d857c30,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details"
    }, {
      path: "addresses-details/create",
      component: _b6eab608,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details-new"
    }, {
      path: "addresses-details/edit/:addressId",
      component: _0661e944,
      meta: {"titleLabel":"Addresses details"},
      props: true,
      name: "customer-addresses-details-edit"
    }, {
      path: "my-newsletter",
      component: _a9a72d98,
      meta: {"titleLabel":"My newsletter"},
      name: "customer-my-newsletter"
    }, {
      path: "my-wishlist",
      component: _7d90f100,
      meta: {"titleLabel":"My wishlist"},
      name: "customer-my-wishlist"
    }, {
      path: "order-history",
      component: _41172270,
      meta: {"titleLabel":"Order history"},
      name: "customer-order-history"
    }, {
      path: "order-history/:orderId",
      component: _1acd6a83,
      meta: {"titleLabel":"Order history"},
      props: true,
      name: "customer-single-order"
    }, {
      path: "my-reviews",
      component: _251bbdb0,
      meta: {"titleLabel":"My reviews"},
      name: "customer-my-reviews"
    }, {
      path: "dashboard",
      component: _152a38f5,
      meta: {"titleLabel":"My dashboard"},
      name: "customer-dashboard"
    }, {
      path: "my-invoices-and-credit-notes",
      component: _a6bae846,
      meta: {"titleLabel":"My invoices and credits note"},
      name: "customer-my-invoices-and-credit-notes"
    }, {
      path: "my-frequent-purchases",
      component: _7b72f539,
      meta: {"titleLabel":"My frequent purchases"},
      name: "customer-my-frequent-purchases"
    }, {
      path: "my-quotations",
      component: _5165f9e2,
      meta: {"titleLabel":"My quotations"},
      name: "customer-my-quotations"
    }, {
      path: "my-quotations/:quotationId",
      component: _183d5480,
      meta: {"titleLabel":"My quotations"},
      props: true,
      name: "customer-single-quotation"
    }, {
      path: "/reset-password",
      component: _7f9693eb,
      alias: "/customer/account/createPassword",
      name: "reset-password"
    }, {
      path: "my-sub-accounts",
      component: _ddd2e072,
      meta: {"titleLabel":"My sub accounts"},
      name: "customer-my-sub-accounts"
    }, {
      path: "create-sub-accounts",
      component: _8da45724,
      meta: {"titleLabel":"Create sub accounts"},
      props: true,
      name: "customer-create-sub-accounts"
    }, {
      path: "update-sub-account/:subAccountId",
      component: _8da45724,
      meta: {"titleLabel":"Update sub account"},
      props: true,
      name: "customer-update-sub-account"
    }, {
      path: "orders-to-approve",
      component: _449da56e,
      meta: {"titleLabel":"Orders waiting for approval"},
      name: "customer-orders-waiting-for-approval"
    }, {
      path: "orders-to-approve-detail/:orderId",
      component: _7f388da1,
      meta: {"titleLabel":"Order waiting for approval"},
      props: true,
      name: "customer-order-waiting-for-approval-detail"
    }]
  }, {
    path: "/catalogue/:slug?",
    component: _074de8ac,
    name: "category"
  }, {
    path: "/catalogue/:slug(.*)*",
    component: _64d22eb4,
    name: "page_ibexia"
  }, {
    path: "/search",
    component: _074de8ac,
    name: "search"
  }, {
    path: "/promo",
    component: _074de8ac,
    name: "promo"
  }, {
    path: "/produit/:slug?",
    component: _5bc47273,
    name: "product",
    beforeEnter: function(to, from, next) {
          if (!to.params.slug) {
            next({
              name: 'category',
              replace: true
            });
          } else {
            next();
          }
        }
  }, {
    path: "/no-result-found",
    component: _07bf8c62,
    name: "no-result-found"
  }, {
    path: "/:slug+",
    component: _2cd3849d,
    name: "page"
  }, {
    path: "/",
    component: _46c32226,
    name: "home"
  }, {
    path: "/:pathMatch(.*)*",
    component: _0fb3efcf,
    name: "not-found"
  }, {
    path: "/Home",
    component: _46c32226,
    name: "Home"
  }, {
    path: "/Page",
    component: _2cd3849d,
    name: "Page"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
